.main-holder {
  gap: 20px;
  margin: auto;
  width: 400px;
  height: 600px;
  display: flex;
  padding: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title-wrapper {
  gap: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.title-wrapper img {
  width: 100px;
}

.inbox-holder {
  width: 100%;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  list-style: none;
  border-radius: 8px;
  border: 1px solid rgba(var(--border-color), 0.2);
}

.inbox-holder::-webkit-scrollbar {
  width: 4px;
  position: absolute;
}

.inbox-holder::-webkit-scrollbar-track {
  background: transparent;
}

.inbox-holder::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--text-color);
}

.confirm-button-holder {
  width: 100%;
}

.confirm-button {
  width: 100%;
  color: white;
  height: 48px;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 8px;
  transition: all 300ms;
  background: var(--primary-color);
}

.confirm-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.inbox {
  height: 48px;
  font-size: 20px;
  padding: 0 16px;
  cursor: pointer;
  overflow: hidden;
  line-height: 48px;
  white-space: nowrap;
  transition: all 300ms;
  text-overflow: ellipsis;
  color: var(--text-color);
  border-bottom: 1px solid rgba(var(--border-color), 0.2);
}

.inbox:hover,
.inbox.selected {
  color: white;
  background: var(--primary-color);
}

.inbox.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

h1 {
  color: var(--primary-color);
  font-size: 48px;
}

.loader {
  width: 60px;
  height: 60px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid var(--primary-color);
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1500ms linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

.success-animation { margin:150px auto;}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--primary-color);
  stroke-miterlimit: 10;
  position:relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: var(--background);
  stroke: var(--primary-color);
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
