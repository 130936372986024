* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --text-color: #444;
  --background: white;
  --border-color: 0, 0, 0;
  --primary-color: #603ae9;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #222;
    --text-color: white;
    --primary-color: #6943c3;
    --border-color: 255, 255, 255;
  }
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
